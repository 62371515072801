<template>
  <div class="mappingCenterAll">
    <div class="mappingCenterFirst">
      <div class="mappingCenterFirst1">
        <h1>御之安网络资产测绘平台</h1>
        <p>
          基于自主研发的网络空间测绘技术，对企业内部网络资产数据进行全面的采集，配合漏洞扫描、资产合规性监测等安全防护模块，为企业的网络系统提升强大的安全监控和应急响应能力
        </p>
      </div>
    </div>
    <div class="box">
      <ul class="tabs clearfix">
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proGs').scrollIntoView()"
        >
          产品概述
        </li>
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proJg').scrollIntoView()"
        >
          产品架构
        </li>
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proYs').scrollIntoView()"
        >
          产品优势
        </li>
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proGC').scrollIntoView()"
        >
          测绘过程
        </li>
      </ul>
    </div>
    <div class="mappingCenterSecond">
      <h1 id="proGs">产品概述</h1>
      <div class="mappingCenterSecond1">
        <div class="mappingCenterSecond1Left">
          <img src="../../../../assets/img/proctCenter/6.webp" alt="" />
        </div>
        <div class="mappingCenterSecond1Right">
          <p>
            随着互联网信息技术的发展，安全形态快速发生变化，传统的安全边界逐步失效，各类违规接入、私搭乱建、违规操作的现象越发严重，为信息安全带来极大挑战。同时，企事业的IT资产数量飞速增长，员工和系统管理的安全要求也在不断增加。事实证明，99%的攻击事件都利用了未修补的漏洞，使得许多已经部署了防火墙、入侵检测系统和防病毒软件的企业仍然饱受漏洞攻击之苦，蒙受巨大的经济损失
          </p>
          <p>
            企业网络资产存在不直观、种类多、变化快的特性，企业安全管理面临评估困难、定位困难、整改困难、管控困难问题。在这样的大背景下，御之安科技推出了网络资产测绘管理平台，这是一款针对90%攻击手段进行主动防御的自动化风险检测平台产品。
          </p>
        </div>
      </div>
    </div>
    <div class="mappingCenterThird">
      <h1 id="proJg">产品架构</h1>
      <p>
        通过资产和漏洞管理模块，提前探测到资产并利用自动调度漏洞扫描器、渗透测试漏洞管理、漏洞检测、弱口令探测等能力。协助安全管理人员轻松应对漏洞利用、漏洞攻击和弱口令等攻击手段
      </p>
      <div class="mappingCenterThird1">
        <img src="../../../../assets/img/proctCenter/9.webp" alt="" />
      </div>
    </div>
    <div class="mappingCenterFourth">
      <h1 id="proYs">产品优势</h1>
      <div class="mappingCenterFourth1">
        <div class="mappingCenterFourth1Right">
          <img src="../../../../assets/img/proctCenter/10.webp" alt="" />
        </div>
        <div class="mappingCenterFourth1Left">
          <h2>立竿见影的效果</h2>
          <p>
              完全从真实网络对抗场景出发，各模块之间配合进行立竿见影的精准风险检测和预测，以协助您做到防微杜渐、未雨绸缪的效果。
          </p>
          <h2>一站式技术风险解决方案</h2>
          <p>
              内置全资产检测、信息泄露检测、1DAY漏洞检测等模块，配合扫描器管理、渗透测试管理等模块，各模块可依据需要灵活组合、协作和独立工作，为您提供一站式技术风险解决方案。
          </p>
          <h2>业务零感知</h2>
          <p>
              平台各模块以无感和旁路检测方式工作，对业务系统和业务运营不造成任何影响。
          </p>
          <h2>关注最重要的90%</h2>
          <p>
              国际一流的御之安科技联合实验室战队矩阵,基于长期一线网络对抗经验，我们将有限的精力和资源聚焦在最重要的90%攻击上，辅助您抵御国内外黑客攻击。
          </p>
        </div>
      </div>
    </div>
    <div class="mappingCenterFiveth">
      <h1 id="proGC">测绘过程</h1>
      <div class="mappingCenterFiveth1">
        <img src="../../../../assets/img/proctCenter/8.webp" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 767px) {
  .mappingCenterAll {
    width: 100%;
    .box {
      margin: 0 auto;
    }
    .tabs {
      display: flex;
      justify-content: center;
      height: 65px;
      padding: 0px 40px;
      border-bottom: 1px solid #dadada;
    }
    .tabs li {
      float: left;
      // margin-right: 87px;
      list-style: none;
    }

    .tabs .tab-link {
      display: block;
      width: 270px;
      height: 65px;
      text-align: center;
      line-height: 65px;
      font-size: 14px;
      color: #000;
      text-decoration: none;
    }
    .tabs .tab-link:hover {
      cursor: pointer;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 5px solid #3d4ed0;
      transition: 0.3s;
    }
    .mappingCenterFirst {
      width: 100%;
      height: 300px;
      background-image: url(../../../../assets/img/proctCenter/6.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 80px 40px;
      .mappingCenterFirst1 {
        width: 100%;
      }
      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
        color: #fff;
      }
      p {
        font-size: 14px;
        margin-top: 10px;
        color: #fff;
      }
    }
    .mappingCenterSecond {
      margin-top: 40px;
      background-color: #f6f6f6;
      padding: 20px 40px;
      h1 {
        font-size: 18px;
        color: rgb(31, 35, 41);
        margin-bottom: 30px;
        text-align: center;
      }
      .mappingCenterSecond1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        .mappingCenterSecond1Left {
          margin-right: 30px;
          img {
            width: 72vw;
            height: 38vh;
          }
        }
        .mappingCenterSecond1Right {
          p {
            text-indent: 1.5rem;
            font-size: 14px;
            font-family: "微软雅黑";
            color: #51565d;
            margin-top: 10px;
            line-height: 1.8;
          }
        }
      }
    }
    .mappingCenterThird {
      margin-top: 40px;
      background-color: #f6f6f6;
      padding: 20px 40px;
      h1 {
        font-size: 18px;
        color: rgb(31, 35, 41);
        margin-bottom: 30px;
        text-align: center;
      }
      p {
        text-indent: 1.5rem;
        font-size: 16px;
        font-family: "微软雅黑";
        color: #51565d;
        margin-top: 10px;
        line-height: 1.8;
        text-align: center;
      }
      .mappingCenterThird1 {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        img {
          width: 72vw;
          height: 38vh;
        }
      }
    }
    .mappingCenterFourth {
      margin-top: 40px;
      background-color: #f6f6f6;
      padding: 20px 40px;
      h1 {
        font-size: 18px;
        color: rgb(31, 35, 41);
        margin-bottom: 30px;
        text-align: center;
      }
      .mappingCenterFourth1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .mappingCenterFourth1Left {
          h2 {
            font-size: 16px;
            color: #1f2329;
          }
          p {
            font-size: 16px;
            font-family: "微软雅黑";
            color: #51565d;
            margin: 10px 0;
            line-height: 1.8;
          }
        }
        .mappingCenterFourth1Right {
          img {
            width: 72vw;
            height: 38vh;
          }
        }
      }
    }
    .mappingCenterFiveth {
      margin-top: 40px;
      background-color: #f6f6f6;
      padding: 20px 40px;
      h1 {
        font-size: 18px;
        color: rgb(31, 35, 41);
        margin-bottom: 30px;
        text-align: center;
      }
      .mappingCenterFiveth1 {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        img {
          width: 72vw;
          height: 38vh;
        }
      }
    }
  }
}
</style>